/* Container styling */
.container {
    background: linear-gradient(135deg, #FFDEE9 0%, #B5FFFC 100%); /* Romantic pink and light blue gradient */
    padding: 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* Header styling */
.header {
    font-family: 'Dancing Script', cursive; /* A romantic, cursive font */
    color: #FF6F91; /* Soft pink for the header */
    font-size: 48px;
    margin-bottom: 20px;
    animation: fadeIn 2s ease-in-out;
}

/* Paragraph styling */
.paragraph {
    font-family: 'Roboto', sans-serif;
    color: #6D6875; /* A soft gray for contrast */
    font-size: 20px;
    line-height: 1.6;
    margin: 10px 0;
    animation: fadeIn 3s ease-in-out;
}

/* Adding fade-in animation */
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}